(() => {
    window.addEventListener('DOMContentLoaded', () => {
        const selectShop = document.getElementById('selectShop');

        if (selectShop) {
            selectShop.addEventListener('change', () => {
                const updatedUrl = new URL(selectShop.dataset.route);

                updatedUrl.searchParams.set('shop-id', selectShop.value || '0');

                window.location.href = updatedUrl.toString();
            });
        }
    });
})();
